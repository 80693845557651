@import './_variables.scss';

/* card list */
.card-row {
  width: 100%;
  display: flex;

  .car-box {
    // width: 50%;
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    padding: 12px 12px;
    background-color: #fff;
    border: 1px solid #f5f5f5;
    border-radius: 12px;
    margin-right: 16px;
    margin-bottom: 16px;
  }

  .car-box .image-box {
    width: 52px;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    background-color: #fafafa;
    margin-bottom: 28px;

    img {
      width: 100%;
      height: auto;
    }
  }

  .car-box .card-info {
    width: 100%;
  }

  .car-box .card-info h3 {
    font-size: 14px;
    color: #212121;
    font-weight: 500;
    margin: 0px 0px 10px 0px;
  }

  .car-box .card-info p {
    display: none;
  }

  .arrow_bt {
    display: none;
  }
}
/* list view */

.list-view .car-box {
  flex-wrap: nowrap;
  align-items: center;

  .image-box {
    margin-bottom: 0px;
    margin-right: 16px;

    img {
      width: 100%;
      height: auto;
    }
  }

  .card-info {
    width: auto;
  }

  .card-info h3 {
    margin-bottom: 4px;
  }

  .card-info p {
    display: block;
    font-size: 12px;
    color: #898989;
    font-weight: 400;
    margin: 0px;
  }
}

.list-view .arrow_bt {
  display: block;
  position: absolute;
  right: 0px;
  top: 50%;
  margin-top: -12px;
  background: transparent;
  border: 0px;
  outline: 0px;
  color: #898989;
}
.arrow_bt span {
  vertical-align: middle;
}
/* list view */
