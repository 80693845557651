/* make the customizations */
$theme-colors: (
  'clr-primary': #19bc9d,
  'clr-text-1': #555555,
  'clr-text-2': #898989,
  'clr-background-1': #fafafa,
  'clr-background-2': #19bc9c0d,
  'clr-background-3': #8989890d,
  'clr-border-1': #f6f6f6,
  'clr-border-2': #f5f5f5,
  'clr-background-danger': #df0d171a,
);

/* import bootstrap to set changes */
@import '~bootstrap/scss/bootstrap';
