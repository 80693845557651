@import './_variables.scss';

.filter-section {
  width: 360px;
  height: 100%;
  background: #fff;
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 3;
  transform: translateX(360px);
  transition: 0.3s ease;
}
.filter-open {
  transform: translateX(0px);
}
.filter-section .filter-wrap {
  width: 100%;
  height: 100%;
  position: relative;
}
.filter-section .filter-header {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 16px 16px;
  background: $clr-primary;
}
.filter-section .filter-header button {
  background: transparent;
  border: 0px;
  color: #fff;
  outline: 0px;
  padding: 0px;
  margin-right: 32px;
}
.filter-section .filter-header button span {
  vertical-align: middle;
}
.filter-section .filter-header h2 {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  color: #fff;
  margin: 0px;
}
.filter-section .filter-body {
  width: 100%;
  padding: 16px 16px;
}
.filter-section .filter-heading h3 {
  font-size: 14px;
  font-weight: 500;
  color: #000;
  border-bottom: 1px solid #f5f5f5;
  padding-bottom: 16px;
  margin-bottom: 16px;
}
.filter-label-section {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 26px;
}
.filter-label-section .filter-label {
  width: auto;
  transition: 0.2s ease;
  display: flex;
  align-items: center;
  padding: 8px;
  background: #8989890d;
  border: 1px solid #7070700d;
  border-radius: 8px;
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}
.filter-label-section .filter-label img {
  margin-right: 4px;
}
.filter-label-section .filter-label img.selected_icon {
  display: none;
}
.filter-label-section .filter-label h4 {
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  color: #898989;
  margin: 0px;
}
.filter-label-section .filter-label.active {
  background: #19bc9c0d;
  border: 1px solid #19bc9c33;
}
.filter-label-section .filter-label.active img {
  display: none;
}
.filter-label-section .filter-label.active img.selected_icon {
  display: block;
}
.filter-label-section .filter-label.active h4 {
  color: $clr-primary;
}
.filter-label-section .filter-label:nth-child(even) {
  margin-right: 0px;
}
.filter-button {
  position: absolute;
  bottom: 0px;
  left: 0px;
  padding: 14px 16px;
  background: #fff;
  width: 100%;
  display: flex;
  box-shadow: 0px -3px 5px #0000000d;
}
.filter-button button {
  width: 50%;
}
