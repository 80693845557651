@import './_variables.scss';

.current-location {
  width: 100%;
  background: #19bc9c1f;
  border: 1px solid #f5f5f5;
  padding: 16px 16px;
  border-radius: 12px;
  margin-bottom: 14px;
}
.current-location h4 {
  font-size: 12px;
  line-height: 20px;
  color: #555555;
  margin: 0px 0px 8px 0px;
}
.current-location h4 img {
  margin-right: 8px;
}
.current-location p {
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  margin: 0px;
}
