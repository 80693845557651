@import './_variables.scss';

// common header
.header-section {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 0px 24px;
  background: $clr-primary;

  .top-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 14px 0px;
  }
  .tab-section .tab_box {
    width: 100%;
  }
  .tab-section {
    width: 100%;
  }
}

.page-title {
  width: auto;
  display: flex;
  align-items: center;

  h1 {
    font-size: 20px;
    line-height: 24px;
    color: #f5f5f5;
    font-weight: 500;
    margin: 0px;
  }

  button {
    background: transparent;
    border: 0px;
    color: #ffffff;
    outline: 0px;
    margin-right: 32px;
    padding: 0px;
  }

  img {
    vertical-align: middle;
  }
}

.action-section button {
  background: transparent;
  border: 0px;
  color: #f5f5f5;
  outline: 0px;
  margin-right: 16px;
  padding: 0px;

  button:last-child {
    margin-right: 0px;
  }

  img {
    vertical-align: middle;
  }
  button {
    vertical-align: middle;
  }
  span {
    vertical-align: middle;
  }
}

// home header
.bottom-header {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 14px 0px;

  .logo-section img {
    margin-right: 12px;
  }

  .logo-info h2 {
    font-size: 20px;
    line-height: 24px;
    color: #f5f5f5;
    font-weight: 500;
    margin: 0px;
  }

  .logo-info p {
    font-size: 12px;
    line-height: 24px;
    color: #f5f5f5;
    font-weight: 400;
    margin: 0px;
  }
}

/* search input */
.search-box {
  width: 100%;
  display: flex;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 3px 6px #0000000d;
}
.search-box button {
  background: transparent;
  border: 0px;
  color: #898989;
  outline: 0px;
  margin: 0px 16px;
  padding: 0px;
}
.search-box button span {
  vertical-align: middle;
}
.search-box input {
  width: 100%;
  background-color: transparent;
  border: 0px;
  outline: 0px;
  color: #898989;
  font-size: 16px;
  line-height: 20px;
  padding: 13px 0px;
}
/* search input */
