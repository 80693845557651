/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
/* end */
.route-input-section {
  position: relative;
  height: calc(100vh - 60px);
  /* calc(100vh - 53px); */
}
.search-left ul {
  padding-left: 0;
  list-style-type: none;
}

.search-left img,
.search-left1 img {
  height: 24px !important;
}

.route-running-img > .mediumSizeImg {
  height: 20px !important;
}
.smallImgDiv > .smallImg {
  height: 14px !important;
}
.route-running-main {
  padding: 0 5px 0 24px; /*24px 16px 24px 32px; */
  max-height: calc(100vh - 128px);
  overflow-y: auto;
}
.route-running-list {
  position: relative;
}

.route-searched:before {
  content: '';
  width: 8px;
  background-color: #f3f3f3;
  border-radius: 0px;
  position: absolute;
  top: -36px;
  left: 0;
  height: calc(100% - 24px);
}

.route-searched-travelled-before-Vehicle:before,
.liveVehicleTimeline:before {
  content: '';
  width: 8px;
  background-color: #19bc9d;
  border-radius: 0px;
  position: absolute;
  /* top: -36px; */
  top: 48px;
  left: 0;
  height: calc(100% - 24px);
  z-index: 15;
}

.liveVehicleTimeline:before {
  background-color: #19bc9d;
  /* background-color: yellow; */
  /* z-index: 10; */
  top: 48px;
  height: calc(100% - 60px);
}

.greenMyElem {
  background-color: #f6fdfc;
  color: #19bc9d;
}
.route-searched-nottravelled-after-Vehicle-before-destination:before {
  content: '';
  width: 8px;
  background-color: #b7e0d8;
  border-radius: 0px;
  position: absolute;
  /* top: -36px; */
  top: 48px;

  left: 0;
  height: calc(100% - 24px);
}

.route-searched-default:before {
  content: '';
  width: 8px;
  background-color: #f3f3f3;
  border-radius: 8px;
  position: absolute;
  /* top: -36px; */
  top: 48px;
  left: 0;
  height: calc(100% - 24px);
}

.route-running-list:before,
.current-stop-list:before {
  content: '';
  width: 8px;
  background-color: #f3f3f3;
  border-radius: 8px;
  position: absolute;
  top: -24px;
  left: 0;
  height: calc(100% - 24px);
}
.current-stop-list:before {
  background-color: #b7e0d8;
  top: 60px;
  height: calc(100% - 123px);
}

.route-running-theme {
  position: relative;
}
.route-running-img {
  position: absolute;
  left: -14px;
  top: 24px;
  width: 36px;
  height: 36px;
  text-align: center;
}
.route-running-img img {
  max-width: 100%;
}
.route-running-text {
  margin-left: 28px;
  padding: 24px 16px 16px;
  border-bottom: 1px solid #f5f5f5;
}
.route-running-text h2,
.current-stop-text h3 {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
  color: #555;
}
.route-running-time-sec {
  display: flex;
  justify-content: space-between;
}
.route-running-time-item {
  display: flex;
  align-items: center;
}
.route-running-time-item span {
  font-size: 12px;
  color: #555;
  margin-right: 8px;
}
.route-running-time-item .material-symbols-outlined {
  font-size: 16px;
}
.route-running-time-item .reached-time {
  color: #19bc9c;
}

.current-stop-sec {
  position: absolute;
  width: 100%;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 16px 1px 16px;
  background-color: #fff;
  box-shadow: 0 -8px 10px rgb(0 0 0 / 10%);
}
.current-stop-text p {
  color: #19bc9c;
  font-size: 12px;
  margin-bottom: 0;
}
.on-time-sec {
  background-color: rgb(25 188 156 / 10%);
  color: #19bc9c;
  font-size: 12px;
  padding: 8px;
  border-radius: 4px;
  display: flex;
  align-items: center;
}
.on-time-sec .material-symbols-outlined {
  font-size: 16px;
  margin-right: 4px;
}
.time-status {
  font-weight: 500;
}

/* blink */
.blink_me {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
