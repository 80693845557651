$clr-primary: #19bc9d;
$clr-background-1: #fafafa;
$clr-border-1: #f6f6f6;
$clr-border-2: #f5f5f5;
$clr-sub-title: #898989;
$clr-border-3: #707070;

// scrollbar
$clr-scrollbar-shadow: #0000004d;
$clr-scrollbar-bg: #f5f5f5;
