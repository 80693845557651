/* splash screen */
.splash-section {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.splah-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: space-between;
  height: 100vh;
  padding: 36px 0px;
}
.splah-wrap .logo-section {
  width: 100%;
  text-align: center;
}
.splah-wrap .logo-section .logo {
  width: 100%;
  margin-bottom: 32px;
}
.splah-wrap .info-section {
  width: 100%;
}
.splah-wrap .info-section h1 {
  font-size: 42px;
  font-weight: 700;
  line-height: 55px;
  margin-bottom: 6px;
  color: #000000;
}
.splah-wrap .info-section p {
  font-size: 16px;
  color: #898989;
  line-height: 20px;
  margin-bottom: 0px;
  font-weight: 400;
}
.splah-wrap .bottom-graphic {
  width: 100%;
  text-align: center;
}
.splah-wrap .bottom-graphic .graphic-section {
  margin-bottom: 24px;
}
.branding-section {
  width: 100%;
  text-align: center;
}
/* splash screen */
