@import './_variables.scss';

.search-section .right-section {
  width: calc(100% - 360px);
  height: 100%;
  position: relative;
  overflow-x: hidden;
  // added
  margin: 0 auto;
}
.right-section .floating-btn {
  position: fixed;
  right: 24px;
  bottom: 24px;
}

/* ticket */
.ticket-section {
  max-width: 680px;
  width: 100%;
  margin: 0 auto;
  padding: 20px 0px;
  height: calc(100vh - 60px);
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 10px;
}

.ticket-heading {
  width: 100%;
  text-align: center;
  margin-bottom: 16px;

  h2 {
    font-size: 16px;
    line-height: 20px;
    color: #898989;
    margin: 0px;
    font-weight: 400;

    span {
      color: #555555;
      font-weight: 500;
    }
  }
}

.ticket-wraper {
  width: 100%;
}
.ticket-card {
  width: 100%;
  background: #fff;
  border: 1px solid #f5f5f5;
  padding: 16px 24px;
  border-radius: 8px;
  box-shadow: 0px 4px 10px #ffacac0d;
  margin-bottom: 16px;
  position: relative;

  .from-to {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    margin-bottom: 3px;

    &::before {
      width: 100%;
      height: 1px;
      background-color: #dbdbdb;
      position: absolute;
      top: 50%;
      left: 0px;
      content: '';
      z-index: 1;
      margin-top: -1px;
    }
    div {
      background-color: #fff;
      z-index: 2;

      p {
        font-size: 12px;
        font-weight: 400;
        color: #898989;
        margin: 0px;
      }
    }

    .from-section p {
      padding-right: 15px;
    }

    .distance-time p {
      padding: 0px 5px;
      display: flex;
      align-items: center;

      img {
        margin-right: 5px;
      }
    }

    .to-section p {
      padding-left: 15px;
    }
  }

  .place-name {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 14px;

    h4 {
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      color: #000066;
      margin: 0px;
      max-width: 40%;
    }
  }

  .info-section {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h5 {
      font-size: 12px;
      color: #898989;
      font-weight: 400;
      margin-bottom: 2px;
    }

    p {
      font-size: 12px;
      color: #555555;
      font-weight: 500;
      margin-bottom: 0px;
    }
    div:nth-child(2) {
      text-align: center;
    }
    div:last-child {
      text-align: end;
    }
  }

  .devider-line {
    width: 100%;
    height: 2px;
    background: url('../assets/searchResult/ticker-line.png');
    margin: 16px 0px;
    position: relative;
    opacity: 0.5;

    &::before {
      width: 50px;
      height: 48px;
      position: absolute;
      content: '';
      left: -40px;
      top: -19px;
      background: url('../assets/searchResult/Polygon-left.png');
    }
    &::after {
      width: 50px;
      height: 48px;
      position: absolute;
      content: '';
      right: -42px;
      top: -19px;
      background: url('../assets/searchResult/Polygon-right.png');
    }
  }
}
